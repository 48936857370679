import React from "react";
import { ImageBackground, StyleSheet } from "react-native";
import IMAGE from "../../assets/YouWriteWeDeliver.png";

const styles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: "center",
    //paddingLeft: 10,
  },
});

export default function BackgroundImage({ children }) {
  return (
    <ImageBackground
      resizeMode="contain"
      source={IMAGE}
      style={styles.image}
      blurRadius={0}
    >
      {children}
    </ImageBackground>
  );
}
