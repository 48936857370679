import React from "react";
import {
  Text,
  StyleSheet,
  TouchableHighlight,
  TouchableWithoutFeedback,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";

const styles = StyleSheet.create({
  buttonStyle: {
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "#2a6bcc",
    borderRadius: 10,
    //borderWidth: 2,
    alignSelf: "center",
  },
  textStyle: {
    fontSize: 18,
    color: "black",
    fontFamily: "roboto-regular",
  },
});

const DropDown = ({
  onChange,
  open,
  value,
  items,
  setOpen,
  setValue,
  setItems,
  onPress,
}) => (
  <DropDownPicker
    onPress={onPress}
    mode="BADGE"
    placeholder="Select Recipient(s)"
    containerStyle={{
      height: 60,
      //borderWidth: 5,
      paddingLeft: 5,
      borderColor: "#2a6bcc",
    }}
    itemStyle={{ alignItems: "center" }}
    labelStyle={{ fontSize: 20, color: "#fff" }}
    activeItemStyle={{ alignItems: "center" }}
    placeholderStyle={{ padding: 10, fontWeight: "bold" }}
    activeLabelStyle={{ color: "Blue" }}
    arrowStyle={{ marginRight: 10 }}
    arrowIconStyle={{
      width: 10,
      height: 10,
    }}
    tickIconStyle={{
      width: 10,
      height: 10,
    }}
    closeIconStyle={{
      width: 20,
      height: 20,
    }}
    iconContainerStyle={{
      marginRight: 10,
    }}
    arrowIconContainerStyle={{
      marginRight: 10,
    }}
    tickIconContainerStyle={{
      marginRight: 10,
    }}
    closeIconContainerStyle={{
      marginRight: 10,
    }}
    selectedItemLabelStyle={{ color: "blue" }}
    multiple={true}
    onChangeText={onChange}
    open={open}
    value={value}
    items={items}
    setOpen={setOpen}
    setValue={setValue}
    setItems={setItems}
    closeAfterSelecting={true}
    closeOnBackPressed={true}
    close
    itemSeparator={true}
    listItemContainer={{
      height: 40,
      fontSize: 20,
      fontFamily: "roboto-regular",
    }}
    listItemLabelStyle={{
      fontSize: 15,
      fontFamily: "roboto-regular",
    }}
    dropDownContainerStyle={{
      backgroundColor: "#dfdfdf",
      padding: 5,
      fontFamily: "roboto-regular",
    }}
    badgeStyle={{
      padding: 5,
    }}
    badgeTextStyle={{
      //fontStyle: "italic",

      fontSize: 16,
      fontFamily: "roboto-bold",
      color: "white",
    }}
    badgeDotStyle={{
      borderRadius: 5,
    }}
    badgeSeparatorStyle={{
      width: 30,
    }}
    badgeColors={["black"]}
    badgeDotColors={["white"]}

    //value={value}
  />
);

export default DropDown;
