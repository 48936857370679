import React, { useState } from "react";
import { View, StyleSheet, Text, Platform } from "react-native";
import Auth from "@aws-amplify/auth";
import Button from "../../components/Button";
import LongButton from "../../components/LongButton";
import Input from "../../components/CustomTextInput";
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 100,
    backgroundColor: "#FFFBE7",
  },
  inputUnderLine: {
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: "grey",
    padding: 10,
    fontSize: 20,
    //textTransform: "uppercase",
    margin: 2,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
  },
});

const Confirmation = ({ route, navigation }) => {
  const [authCode, setAuthCode] = useState("");
  const [error, setError] = useState(" ");
  console.log("confirmation navigation: ", navigation);
  const { email } = route.params;

  const confirmSignUp = async () => {
    if (authCode.length > 0) {
      await Auth.confirmSignUp(email, authCode)
        .then(() => {
          navigation.navigate("SignIn");
        })
        .catch((err) => {
          if (!err.message) {
            setError("Something went wrong, please contact support!");
          } else {
            setError(err.message);
          }
        });
    } else {
      setError("You must enter confirmation code");
    }
  };

  return (
    <View style={styles.container}>
      <Text>Check your email for the confirmation code.</Text>
      <Input
        value={authCode}
        placeholder="123456"
        onChangeText={(text) => setAuthCode(text)}
        label="email code"
        onSubmitEditing={() => confirmSignUp()}
      />
      <View style={styles.fixToText}>
        {Platform.OS == "web" ? (
          <Button onPress={() => confirmSignUp()}>Confirm Sign Up</Button>
        ) : (
          <LongButton onPress={() => confirmSignUp()}>
            Confirm Sign Up
          </LongButton>
        )}
      </View>
      <Text>{error}</Text>
    </View>
  );
};

export default Confirmation;
