import React, { useState, Component, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Keyboard,
  FlatList,
  Alert,
} from "react-native";
import * as mime from "react-native-mime-types";
import Button from "../components/Button";
import LongButton from "../components/LongButton";
import Input from "../components/Input";
import TextBox from "../components/Text";
import DropDown from "../components/DropDown";
import { Platform } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import AttachmentList from "../components/AttachmentList";
import Toast from "react-native-root-toast";

import * as ImagePicker from "expo-image-picker";

import DTPicker from "../components/DTPicker";

import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

import Moment from "moment";
import { SafeAreaView } from "react-native-web";
import { IconBluetooth } from "@aws-amplify/ui-react";

// const DTPicker = Platform.select({
//   ios: () => require("../components/DTPicker").default,
//   android: () => require("../components/DTPicker").default,
//   default: () => require("../components/DTPicker").default,
// })();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: "space-between",
    // backgroundColor: "#fff",
    // padding: 2,
    // margin: 10,
    // zIndex: 30,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    //borderWidth: 1,
    flex: 1,
    flexDirection: "row",
    zIndex: 1,
    elevation: 1,
    position: "relative",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "center",
    justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,
    zIndex: 20,
    position: "relative",
  },
  top: {
    flex: 0.15,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  middle: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    zIndex: 10,
    position: "relative",
  },
  textbox: {
    //flex: 1,
    //backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    position: "relative",
    zIndex: 2,
    elevation: 2,
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  dropdown: {
    flex: 1,
    position: "relative",
    zIndex: 1,
    elevation: 1,
  },
  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    paddingRight: 20,
    justifyContent: "space-around",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    width: 250,

    // borderWidth: 1,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  itemStyle: {
    // fontFamily: "serif",
    alignSelf: "center",
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
  },
  bottomText: {
    // fontFamily: "serif",
    alignSelf: "center",
    fontSize: 20,
  },
  tinyLogo: {
    alignSelf: "center",
    width: 50,
    height: 45,
  },
  titleLogo: {
    alignSelf: "center",
    width: 160,
    height: 50,
  },
  logo: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "space-around",
    paddingTop: 0,
    //borderWidth: 1,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
  },
  button: {
    backgroundColor: "blue",
  },
  cancelButton: {
    backgroundColor: "blue",
  },
  selectedImage: {
    width: 200,
    height: 200,
  },
  flatliststyle: {
    // paddingTop: 30,
    //borderWidth: 1,
    //height: 10,
  },
  attachHeight: {
    height: 80,
  },
});

export default function Letter({ signOut, navigation }) {
  //-------------------START Of UPLOAD MEDIA FUNCTIONALITY-----------------------
  const [asset, setAsset] = useState(null);
  const [progressText, setProgressText] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const selectFile = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    //console.log(result);

    if (!result.cancelled) {
      setProgressText("");
      setAsset(result);

      const atemp = {
        type: result.type,
        uri: result.uri,
        width: result.width,
        height: result.height,
        s3key: "",
      };
      //Begin ---NEW add upload functionality here
      if (isLoading) return;
      setisLoading(true);
      const img = await fetchResourceFromURI(result.uri);

      const extension = result.uri.split(".").pop();
      //console.log("extension:", extension);
      const filename =
        Math.random().toString(36).substring(2, 30) +
        Math.random().toString(36).substring(2, 30) +
        +"." +
        extension;
      result.uri = filename;
      const mimetype = mime.lookup(extension);
      return Storage.put(result.uri, img, {
        level: "public",
        contentType: mimetype,

        progressCallback(uploadProgress) {
          setProgressText(
            `Progress: ${Math.round(
              (uploadProgress.loaded / uploadProgress.total) * 100
            )} %`
          );
          console.log(
            `Progress: ${uploadProgress.loaded}/${uploadProgress.total}`
          );
        },
      })
        .then((res) => {
          setAsset(null);
          setisLoading(false);
          //setAttachment(res);
          //console.log(attachment);
          atemp.s3key = res.key;

          //console.log("atem:", atemp);
          setAttachments((current) => [...current, atemp]);

          Storage.get(res.key)
            .then((result) => {
              console.log("loaded file");

              //console.log(result);
            })
            .catch((err) => {
              setProgressText("Upload Error");
              console.log(err);
            });
        })
        .catch((err) => {
          setisLoading(false);
          setProgressText("Upload Error");
          console.log(err);
        });

      //End -- New add upload functionality here
    }
  };

  const fetchResourceFromURI = async (uri) => {
    const response = await fetch(uri);
    //console.log(response);
    const blob = await response.blob();
    return blob;
  };

  const deleteAttachment = async (index) => {
    //console.log(attachments[index].key);
    //console.log("index", index);

    Storage.remove(index.s3key)
      .then((result) => {
        setAttachments(
          attachments.filter((items) => items.s3key !== index.s3key)
        );
      })
      .catch((err) => {
        setProgressText("delete Error");
        console.log(err);
      });
  };
  const removeAllAttachment = async (attachmentss) => {
    //console.log(attachments[index].key);

    console.log("removeAllAttachments", attachmentss.length);
    for (const attachI of attachmentss) {
      console.log("deleting:", attachI.s3key);
      Storage.remove(attachI.s3key)
        .then((result) => {
          //do nothing
          console.log("deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //--------------------END of UPLOAD MEDIA FUNCTIONALITY------------------------
  const goback = () => {
    // console.log(attachments.length);
    // if (attachments.length > 0) {
    //   (async () => {
    //     await this.removeAllAttachment();
    //   })().then(navigation.goBack());
    // } else {
    //   navigation.goBack();
    // }
    navigation.goBack();
  };

  const addPerson = () => {
    navigation.navigate("AddPerson", { getPersonFunc: getPersons });
  };

  const [items, setItems] = useState([]);

  const getPersons = async () => {
    //console.log("hellow");

    try {
      const { data } = await API.graphql(graphqlOperation(queries.listPersons));

      const arr = data.listPersons.items.map((person) => ({
        value: person.id,
        label: person.fullname,
      }));
      //console.log(arr);
      setItems(arr);
    } catch (err) {
      console.log(err, "error fetching persons");
    }
  };

  const [letter, onChangeLetter] = useState("");

  const [deliverydate, onChangeDeliveryDate] = useState(new Date());

  const [openDT, setOpenDT] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const isFocused = useIsFocused();
  const [attachments, setAttachments] = useState([]);

  const viewAttachment = (key) => {
    navigation.navigate("ViewAttachment", { item: key });
  };
  useEffect(() => {
    // write your code here, it's like componentWillMount
    console.log("helo");
    getPersons();
    const arr = attachments;
    console.log("b:", attachments.length);
    // //----ADD EVENT for back button
    // navigation.addListener("beforeRemove", (e) => {
    //   // Prevent default behavior of leaving the screen
    //   e.preventDefault();

    //   removeAllAttachment(arr).then(navigation.dispatch(e.data.action));
    // });
    // //----END EVENT for BACK BUTTON
  }, [attachments.length]);

  const onChangeDate = (event, selectedDate) => {
    console.log(selectedDate);
    const currentDate = selectedDate;
    //setShow(false);
    onChangeDeliveryDate(selectedDate);
    setOpenDT(false);
  };

  const save = async (tt) => {
    console.log("Saving" + deliverydate + ":");
    if (
      value.length > 0 &&
      typeof deliverydate != undefined &&
      deliverydate != "" &&
      letter.length > 0
    ) {
      try {
        //BUILD attachment array
        const savetype = tt == "schedule" ? 0 : 2;
        const letterDetails = {
          Content: letter,
          DeliveryDate: Moment(deliverydate).format("YYYY-MM-DD"),
          Sent: savetype,
          //attachments: AttachmentArray,
        };
        //console.log(value[0]); // Person ID
        //console.log(deliverydate); //Delivery Date

        const newLetter = await API.graphql({
          query: mutations.createLetters,
          variables: {
            input: letterDetails,
          },
        });

        for (const personI of value) {
          //console.log(personI);
          const newLetterPerson = await API.graphql({
            query: mutations.createLetterToPerson,
            variables: {
              input: {
                letterToPersonLettersId: newLetter.data.createLetters.id,
                letterToPersonPersonId: personI,
                letterID: newLetter.data.createLetters.id,
              },
            },
          });
        }

        //----------SAVE Attachments------//

        for (const attachI of attachments) {
          const newAttachment = await API.graphql({
            query: mutations.createAttachment,
            variables: {
              input: {
                letterID: newLetter.data.createLetters.id,
                type: attachI.type,
                height: attachI.height,
                width: attachI.width,
                uri: attachI.uri,
                s3key: attachI.s3key,
              },
            },
          });
        }
        //END Attachment
      } catch (err) {
        console.log(err, "error creating letters");
      }
      goback();
    } else {
      console.log("error");
      setInvalidMessage("All Fields are Mandatory");
    }
  };
  //console.log(Platform.OS);

  return (
    // <KeyboardAvoidingView
    //   behavior={Platform.OS === "ios" ? "padding" : "height"}
    //   style={styles.container}
    // >
    <KeyboardAvoidingView
      behavior={Platform?.OS == "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
      enabled
      keyboardVerticalOffset={100}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          setOpen(false);
          Keyboard.dismiss;
        }}
      >
        {/* <View style={styles.container}> */}

        <View style={styles.middle}>
          <View style={styles.b}>
            <Button onPress={() => addPerson()}> Add a new Recipient </Button>
            {/* <Button onPress={() => goback()}>Back</Button> */}
          </View>
          <Text>{invalidMessage}</Text>

          <DropDown
            open={open}
            value={value}
            items={items}
            setOpen={setOpen}
            setValue={setValue}
            setItems={setItems}
            //onPress={() => getPersons()}
          />
          {/* </View> */}
          {Platform.OS == "web" ? (
            <DTPicker
              onChangeDeliveryDate={onChangeDeliveryDate}
              deliverydate={deliverydate}
              openDT={openDT}
              setOpenDT={setOpenDT}
            />
          ) : (
            <DTPicker
              onChangeDeliveryDate={onChangeDate}
              deliverydate={deliverydate}
              openDT={openDT}
              setOpenDT={setOpenDT}
            />
          )}

          {/* </View> */}
          {/* <View style={styles.textbox}> */}
          {/* <Button onPress={() => save()}>Save</Button> */}

          <TextBox
            value={letter}
            //placeholder="letter"
            onChange={(letter) => onChangeLetter(letter)}
            underlineColorAndroid="transparent"
            placeholder="Type your letter here"
            placeholderTextColor="grey"
            numberOfLines={5}
            multiline={true}
            //onSubmitEditing={Keyboard.dismiss}
            keyboardType="default"
            //returnKeyLabel="Done"
            scrollEnabled={true}
            onFocus={() => {
              setOpen(false);
            }}
          />
          {/* START of MEDIA */}
          <View style={styles.fixToText}>
            <LongButton onPress={selectFile}>
              {asset ? "Uploading" : "Add"} Attachment
            </LongButton>
          </View>
          <View style={styles.attachHeight}>
            <FlatList
              style={styles.flatliststyle}
              data={attachments}
              renderItem={({ item }) => (
                <AttachmentList
                  item={item}
                  deleteItem={deleteAttachment}
                  viewItem={viewAttachment}
                />
              )}
            />
          </View>
          {/* END of MEDIA */}
          {/* </View> */}

          <Text>{invalidMessage}</Text>
          <View style={styles.fixToText}>
            <LongButton onPress={() => save("schedule")}>
              Schedule Letter
            </LongButton>
            <LongButton onPress={() => save("draft")}>Save Draft</LongButton>
          </View>
          {/* </View> */}
        </View>

        {/* <View style={styles.bottom}>
          <Text style={styles.bottomText}>Add Letter</Text>
        </View> */}
        {/* </View> */}
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}
