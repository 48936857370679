/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:879ad598-91ab-4ccc-a844-475bcc16fcdc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wBNVBPWLd",
    "aws_user_pools_web_client_id": "4uaq8ekkthmm12r1g5ced4iagg",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://x5v5wp5a2nexhdjxwuzwmgecxe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ymuq3nl5zfdi7lzcjh2uedmnba",
    "aws_user_files_s3_bucket": "melettersmedia143937-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
