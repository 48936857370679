import { React } from "react";
import "react-datepicker/dist/react-datepicker.css";

import {
  Text,
  StyleSheet,
  TouchableHighlight,
  TouchableWithoutFeedback,
  Platform,
  View,
  CalendarContainer,
  //forwardRef,
} from "react-native";
import DatePicker from "react-datepicker";
import Button from "./Button";

const styles = StyleSheet.create({
  buttonStyle: {
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
    zIndex: 1,
    elevation: 1,
  },
  textStyle: {
    fontSize: 18,
    color: "white",
    fontFamily: "roboto-regular",
  },
});

var DTPicker;

//console.log("web");
DTPicker = ({ onChangeDeliveryDate, deliverydate, openDT, setOpenDT }) => (
  <View style={styles.buttonStyle}>
    <Text style={styles.textStyle}> Date to Deliver: </Text>
    <DatePicker
      selected={deliverydate}
      onChange={(date) => onChangeDeliveryDate(date)}
      showYearDropdown
      dateFormatCalendar="MMMM"
      yearDropdownItemNumber={25}
      scrollableYearDropdown
      //isClearable
      placeholderText="Pick a date to deliver"
      disabledKeyboardNavigation
      //inline
      //customInput={<ExampleCustomInput />}
      onFocus={(e) => e.target.blur()}
      dateFormat="MMMM d, yyyy"
      textStyle={styles.textStyle}
    />
  </View>
);

export default DTPicker;
