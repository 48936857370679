//AttachmentList.js
import React from "react";
import {
  View,
  TouchableOpacity,
  Button,
  StyleSheet,
  TouchableOpacityComponent,
  Touchable,
  Text,
} from "react-native";
import { Entypo } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import styled from "styled-components/native";
import Moment from "moment";

const styles = StyleSheet.create({
  listcontainer: {
    borderWidth: 1,
  },

  textStyle: {
    fontSize: 15,
    color: "black",
    fontFamily: "roboto-regular",
  },
  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    // paddingTop: 10,
    // paddingBottom: 10,
    // justifyContent: "space-around",
    // alignSelf: "center",
    // alignItems: "center",
    // width: 300,

    //borderWidth: 1,
  },
});

export default function AttachmentList({ item, deleteItem, viewItem }) {
  const filename = item.uri.replace(/^.*[\\\/]/, "");
  return (
    <ComponentContainer>
      <ListContainer style={styles.listcontainer}>
        <View>
          <TouchableOpacity style={styles.b} onPress={() => viewItem(item)}>
            <MaterialIcons name="attachment" size={30} color="midnightblue" />

            <Text style={styles.textStyle}>{filename}</Text>
          </TouchableOpacity>
        </View>
        {deleteItem != undefined ? (
          <IconContainer onPress={() => deleteItem(item)}>
            <MaterialIcons name="delete" size={30} color="midnightblue" />
          </IconContainer>
        ) : (
          <View></View>
        )}
      </ListContainer>
    </ComponentContainer>
  );
}

const ListContainer = styled.View`
  background-color: whitesmoke;
  height: 30px;

  margin-bottom: 3px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
`;
//width: 350px;

const ComponentContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  height: auto;
  width: auto;
`;

const TextItem = styled.Text`
  align-items: center;
  justify-content: center;
  color: black;
  width: 360px;
  height: auto;
  font-size: 20px;
  margin-top: 2px;
  margin-right: 2px;
  margin-left: 2px
  font-family: poppins-regular;
  
`;

const TextTask = styled.Text`
  color: blue;
  font-size: 15px;
  margin-right: 20px;
  font-family: poppins-regular;
  border-radius: 10px;
  width: 200px;
`;

const IconContainer = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  
  
  height: 30px;
  margin-bottom: 5px
  border-radius: 5px;
`;
