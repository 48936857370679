import React, { useRef, useState, Component, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Button,
} from "react-native";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import { Link } from "@aws-amplify/ui-react";
import { Video, AVPlaybackStatus, Audio } from "expo-av";
import { Platform } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import Toast from "react-native-root-toast";
import DTPicker from "../components/DTPicker";

import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

import Moment from "moment";
import { Keyboard, TouchableOpacity } from "react-native-web";

// const DTPicker = Platform.select({
//   ios: () => require("../components/DTPicker").default,
//   android: () => require("../components/DTPicker").default,
//   default: () => require("../components/DTPicker").default,
// })();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 2,
    margin: 10,
    zIndex: 30,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    //borderWidth: 1,
    flex: 1,
    flexDirection: "row",
    zIndex: 1,
    elevation: 1,
    position: "relative",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "center",
    justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,
    zIndex: 20,
    position: "relative",
  },
  top: {
    flex: 0.15,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  middle: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    zIndex: 10,
    position: "relative",
  },
  textbox: {
    //flex: 1,
    //backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    position: "relative",
    zIndex: 2,
    elevation: 2,
    fontFamily: "roboto-regular",
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  dropdown: {
    flex: 1,
    position: "relative",
    zIndex: 1,
    elevation: 1,
  },
  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    paddingRight: 20,
    justifyContent: "space-around",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    width: 200,
    fontFamily: "roboto-regular",
    // borderWidth: 1,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  itemStyle: {
    // fontFamily: "serif",
    alignSelf: "center",
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
  },
  bottomText: {
    // fontFamily: "serif",
    alignSelf: "center",
    fontSize: 20,
    fontFamily: "roboto-regular",
  },
  tinyLogo: {
    alignSelf: "center",
    width: 50,
    height: 45,
  },
  titleLogo: {
    alignSelf: "center",
    width: 160,
    height: 50,
  },
  logo: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "space-around",
    paddingTop: 0,
    //borderWidth: 1,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
    fontFamily: "roboto-regular",
  },
  textstyle: {
    //flex: 0.5,
    fontSize: 20,
    fontFamily: "roboto-regular",
    // borderWidth: 1,
  },
  videostyle: {
    width: 200,
    height: 200,
  },
  container2: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
  },
  video2: {
    alignSelf: "center",
    width: 320,
    height: 200,
  },
  buttons2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function ViewAttachment({ route, signOut, navigation }) {
  const goback = () => {
    navigation.goBack();
  };
  //VIDEO consts start
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  //VIDEO const end

  //console.log("inside edit page:", route.params.item);

  const isMounted = useRef(false);

  const [attachment, setAttachment] = useState({});
  const [actualFile, setActualFile] = useState(null);
  const [open, setOpen] = useState(false);
  const isFocused = useIsFocused();
  // save image
  const SaveToPhone = async () => {
    //console.log(actualFile);
    // Toast.show("image download", {
    //   duration: 1000,
    // });

    const { uri } = await FileSystem.downloadAsync(
      actualFile,
      `${FileSystem.documentDirectory}myletters.jpg`
    ).catch((e) => console.log(" share failed", JSON.stringify(e), url));

    const permission = await MediaLibrary.requestPermissionsAsync();
    if (permission.granted) {
      try {
        const asset = await MediaLibrary.createAssetAsync(uri);
        MediaLibrary.createAlbumAsync("Images", asset, false)
          .then(() => {
            console.log("File Saved Successfully!");
            Toast.show("image save success!!", {
              duration: 1000,
            });
          })
          .catch(() => {
            console.log("Error In Saving File!");
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Need Storage permission to save file");
    }
  };
  useEffect(() => {
    isMounted.current = true;
    // Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
    // Video.playsInSilentModeIOS = true;

    if (actualFile == undefined) {
      getAttachment(route.params.item);
    }
  }, [attachment]);

  const getAttachment = (key) => {
    if (key.type == "image") {
      Storage.get(key.s3key, { level: "public" })
        .then((result) => {
          console.log("loaded file");
          //console.log(result);

          setActualFile(result);
          setAttachment(key);
        })
        .catch((err) => {
          //setProgressText("Upload Error");
          console.log(err);
        });
    }
    if (key.type == "video") {
      Storage.get(key.s3key, {
        level: "public",
      })
        .then((result) => {
          console.log("loaded file");
          //console.log(result);
          setActualFile(result);
          setAttachment(key);
          //console.log("type:", key.type);
          console.log(actualFile);
        })
        .catch((err) => {
          //setProgressText("Upload Error");
          console.log(err);
        });
    }
  };
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setOpen(false);
        Keyboard.dismiss;
      }}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform?.OS == "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
          enabled
          keyboardVerticalOffset={100}
        >
          <View style={styles.middle}>
            {/* <Text>{attachment.type}</Text> */}
            {/* <Text>{attachment.s3key}</Text> */}

            {actualFile &&
              (attachment.type == "image" ? (
                <View onPress={SaveToPhone}>
                  <TouchableWithoutFeedback onPress={SaveToPhone}>
                    <Image
                      source={{ uri: actualFile }}
                      style={{ width: 300, height: 300 }}
                    />
                  </TouchableWithoutFeedback>
                </View>
              ) : (
                <View></View>
              ))}

            {actualFile &&
              (attachment.type == "video" ? (
                <View style={styles.container2}>
                  <Video
                    ref={video}
                    style={styles.video2}
                    source={{
                      uri: actualFile,
                    }}
                    useNativeControls={true}
                    resizeMode="contain"
                    isLooping
                    onPlaybackStatusUpdate={(status) => setStatus(() => status)}
                    isMuted={false}
                    onLoad={async () => {
                      await video.current?.playAsync();
                      await video.current?.pauseAsync();
                    }}
                  />
                  <View style={styles.buttons2}>
                    <Button
                      title={status.isPlaying ? "Pause" : "Play"}
                      onPress={() =>
                        status.isPlaying
                          ? video.current.pauseAsync()
                          : video.current.playAsync()
                      }
                    />
                  </View>
                </View>
              ) : (
                <View></View>
              ))}
          </View>
        </KeyboardAvoidingView>
      </View>
    </TouchableWithoutFeedback>
  );
}
