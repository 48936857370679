import React, {
  useRef,
  useState,
  Component,
  useEffect,
  SafeAreaView,
  Platform,
} from "react";
import {
  Alert,
  Modal,
  Pressable,
  View,
  Text,
  StyleSheet,
  Image,
  FlatList,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import Button from "../components/Button";

import LetterList from "../components/LetterList";
import { useIsFocused } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import { useWindowDimensions } from "react-native";

import { Switch } from "react-native";

import { API, graphqlOperation, Auth } from "aws-amplify";
import DropDownMenu from "../components/DropDownMenu";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import Circle from "../components/CircleLoading";
import { TouchableWithoutFeedback } from "react-native-web";
import { ComponentPropsToStylePropsMap } from "@aws-amplify/ui-react";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 2,
    //margin: 10,
  },
  image: {
    flex: 1,
    //justifyContent: "center",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "flex-end",
    // justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,
  },
  containerSignout: {
    //flex: 1,
    flexDirection: "row",
    padding: 5,
    margin: 1,
    alignContent: "flex-end",
    // justifyContent: "space-around",
    //borderWidth: 2,
    //paddingLeft: 100,
  },

  top: {
    flex: 0.15,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  topL: {
    flex: 0.4,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  middle: {
    flex: 1,
    //backgroundColor: "#FFFBE7", // green
    backgroundColor: "#FFFBE7",
    borderWidth: 3,
    //justifyContent: "space-around",
    flexGrow: 1,
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  b: {
    //flex: 0.5,
    flexDirection: "row",
    // alignContent: "space-between",
    //paddingTop: 5,
    justifyContent: "flex-start",
    // alignSelf: "flex-end",
    alignItems: "center",
    //width: 300,
    // paddingRight: 20,
    //paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 20,
    //borderWidth: 1,
    zIndex: 10,
    //borderWidth: 1,
  },
  switch1: {
    paddingRight: 20,
  },
  zindex5: {
    zIndex: 5,
    flex: 1,
  },
  flatliststyle: {
    paddingTop: 30,
    zIndex: 3,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  dropdown: {
    flex: 1,
    width: 250,
    paddingRight: 0,
    //borderWidth: 1,

    // position: "relative",
    // zIndex: 100,
    // elevation: 1,
    //border: 1,
  },
  bottomText: {
    fontFamily: "roboto-regular",
    alignSelf: "center",
    fontSize: 15,
    padding: 10,
  },
  tinyLogo: {
    alignSelf: "center",
    width: 50,
    height: 45,
  },
  titleLogo: {
    alignSelf: "center",
    width: 250,
    height: 90,
  },
  logo: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "space-around",
    paddingTop: 0,
    //borderWidth: 1,
  },
});

export default function Home({ signOut, navigation }) {
  const addPerson = () => {
    navigation.navigate("AddPerson");
  };
  const addLetter = () => {
    navigation.navigate("AddLetter", { letters: letters });
  };
  const editLetter = (key) => {
    setDirty(true);
    navigation.navigate("EditLetter", { item: key, setDirty: setDirty });
  };
  const viewLetter = (key) => {
    navigation.navigate("ViewLetter", { item: key });
  };
  const [items, setItems] = useState([]);
  const [checked, setChecked] = useState(false);
  const [dirty, setDirty] = useState(true);
  const isMounted = useRef(false);
  const isFocused = useIsFocused();
  const [user, setUser] = useState("");
  // Menu items Drop Down Menu
  const [MenuOpen, setMenuOpen] = useState(false);
  const [MenuValue, setMenuValue] = useState(0);
  const [MenuItems, setMenuItems] = useState([
    { value: 0, label: "Scheduled Letters" },
    { value: 1, label: "Sent Letters" },
    { value: 2, label: "Draft Letters" },
    { value: 3, label: "Received Letters" },
  ]);

  var letters = 2;
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  const onChangeDropMenu = () => {
    //console.log("onChange:" + MenuValue);
  };

  const getLettersSent = async () => {
    try {
      var sentV = 0;
      console.log("getLettersSent");
      // if (!checked) {
      //   sentV = 1;
      // } else {
      //   sentV = 0;
      // }

      sentV = MenuValue;
      if (MenuValue == 3) sentV = 1; //Received = Sent
      else if (MenuValue == 2) sentV = 2; //Draft
      else if (MenuValue == 1) sentV = 1; //scheduled
      else sentV = 0;
      console.log("menuValue:" + MenuValue);

      const listLettersByStatus = /* GraphQL */ `
        query ($sent: Int) {
          SortByDelivered(Sent: $sent, sortDirection: ASC) {
            items {
              id
              DeliveryDate
              Sent
              updatedAt
              createdAt
              owner
              person {
                items {
                  person {
                    emailId
                    fullname
                  }
                }
              }
            }
          }
        }
      `;
      //console.log("lettersSentCalled: " + MenuValue);
      const { data } = await API.graphql(
        graphqlOperation(listLettersByStatus, { sent: sentV })
      );

      //console.log(data);
      const arr = data.SortByDelivered.items.map((letters) => ({
        id: letters.id,
        value: letters.DeliveryDate,
        sent: letters.Sent,
        owner: letters.owner,
      }));
      //console.log(user);

      if (MenuValue == 3) {
        //remove the owner
        setItems(
          arr.filter(function (item) {
            return item.owner != user;
          })
        );
      } else {
        if (MenuValue == 1) {
          //keep only owner
          setItems(
            arr.filter(function (item) {
              return item.owner == user;
            })
          );
        } else setItems(arr);
      }
      //setItems(arr);
      setDirty(false);
    } catch (err) {
      console.log(err, "error fetching Letters");
    }
  };
  const getLettersNotSent = async () => {
    try {
      var sentV;
      console.log("getLettersSNotSent");
      sentV = 0;

      const listLettersByStatus = /* GraphQL */ `
        query ($sent: Int) {
          SortByDelivered(Sent: $sent, sortDirection: ASC) {
            items {
              id
              DeliveryDate
              Sent
              person {
                items {
                  person {
                    emailId
                    fullname
                  }
                }
              }
            }
          }
        }
      `;

      const { data } = await API.graphql(
        graphqlOperation(listLettersByStatus, { sent: sentV })
      );

      const arr = data.SortByDelivered.items.map((letters) => ({
        id: letters.id,
        value: letters.DeliveryDate,
        sent: letters.Sent,
      }));

      setItems(arr);
    } catch (err) {
      console.log(err, "error fetching Letters");
    }
  };

  //function to delete child letters
  const deleteChildLetter = async (key) => {
    //find all the child table keys for deletion
    try {
      // toggleLoading();
      const cLetters = await API.graphql(
        graphqlOperation(queries.listLetterToPersons, {
          filter: { letterID: { eq: key } },
        })
      );
      // console.log(cLetters);
      const arrChildID = cLetters.data.listLetterToPersons.items.map(
        (letters) => ({
          id: letters.id,
          //version: letters._version,
        })
      );

      for (const ids of arrChildID) {
        //console.log("calling delete of ", ids);
        await API.graphql({
          query: mutations.deleteLetterToPerson,
          variables: {
            input: {
              id: ids.id,
              //_version: vers,
            },
          },
        });
      }
    } catch (err) {}
  };

  const deleteItem = async (key) => {
    //toggleLoading();
    //console.log("key" + key);
    try {
      const id2 = await API.graphql({
        query: mutations.deleteLetters,
        variables: {
          input: {
            id: key,
            //_version: vers,
          },
        },
      })

        .then(deleteChildLetter(key))
        .then(() => {
          isMounted.current = true;
          //setChecked(false);
          getLettersNotSent();
        });
      //toggleLoading();
    } catch (err) {
      console.log(err, "error fetching Letters1");
    }
  };

  useEffect(() => {
    // console.log("useEffect_HOME");
    isMounted.current = true;
    console.log("UseEffect");
    //setDirty(true);
    getLettersSent().then((isMounted.current = false));
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setUser(user.username);
      })
      .catch((err) => console.log(err));
  }, [isFocused]);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setMenuOpen(false);
      }}
    >
      <View style={styles.container}>
        <View
          style={
            windowHeight < windowWidth && Platform?.OS != "web"
              ? styles.topL
              : styles.top
          }
        >
          <View style={styles.container2}>
            <Image
              style={styles.titleLogo}
              source={require("./../../assets/HeaderText.png")}
            />
          </View>
          <View style={styles.containerSignout}>
            <Button onPress={() => signOut()}>Sign Out</Button>
          </View>
        </View>

        <View style={styles.middle}>
          <View style={styles.b}>
            <View style={styles.dropdown}>
              <DropDownMenu
                open={MenuOpen}
                value={MenuValue}
                items={MenuItems}
                setOpen={setMenuOpen}
                setValue={setMenuValue}
                setItems={setMenuItems}
                onChange={getLettersSent}
              />
            </View>
            <View style={styles.containerSignout}>
              <Button
                //style={{ width: 100, paddingRight: 30, paddingLeft: 30 }}
                onPress={() => addLetter()}
              >
                Add Letter
              </Button>
            </View>
          </View>

          {/* <ScrollView> */}
          {dirty == false ? (
            <View style={styles.zindex5}>
              <FlatList
                style={styles.flatliststyle}
                data={items}
                renderItem={({ item }) => (
                  <LetterList
                    item={item}
                    deleteItem={deleteItem}
                    editItem={editLetter}
                    viewItem={viewLetter}
                  />
                )}
              />
            </View>
          ) : (
            <View></View>
          )}

          {/* </ScrollView> */}
        </View>

        <View style={styles.bottom}>
          <Text style={styles.bottomText}>
            Keep writing letters for your loved ones. We deliver them on your
            chosen date.
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}
