import React from "react";
import { Text, View, StyleSheet, SafeAreaView, Image } from "react-native";
import Button from "../../components/Button";
import BackgroundImage from "../../components/BackgroundImage";
import { Dimensions } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 2,
    margin: 10,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },

  top: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  middle: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    borderWidth: 3,
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    justifyContent: "space-around",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    width: 200,

    //borderWidth: 1,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  bottomText: {
    //fontFamily: "serif",
    alignSelf: "center",
    fontSize: 20,
  },
});

const Welcome = ({ navigation }) => (
  <View style={styles.container}>
    <View style={styles.top}>
      <View style={styles.b}>
        <Button onPress={() => navigation.navigate("SignIn")}>Sign In</Button>
        <Button onPress={() => navigation.navigate("SignUp")}>Sign Up</Button>
      </View>
    </View>

    <View style={styles.middle}>
      <BackgroundImage />
    </View>

    <View style={styles.bottom}>
      <Text style={styles.bottomText}>
        Keep writing letters for your loved ones. We deliver them on your chosen
        date.
      </Text>
    </View>
  </View>
);
/*
const Welcome = ({ navigation }) => (
  <SafeAreaView style={styles.container}>
    <BackgroundImage>
      <View style={styles.b}>
        <Button onPress={() => navigation.navigate("SignIn")}>Sign In</Button>
      </View>
      <View style={styles.b}>
        <Button onPress={() => navigation.navigate("SignUp")}>Sign Up</Button>
      </View>
      <View style={styles.content}>
        <Text style={styles.title}>
          Keep writing letters for your loved ones. We deliver them on your
          choosen date.
        </Text>
      </View>
    </BackgroundImage>
  </SafeAreaView>
);
*/
export default Welcome;
