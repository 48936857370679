//LetterList.js
import React from "react";
import {
  View,
  TouchableOpacity,
  Button,
  StyleSheet,
  TouchableOpacityComponent,
  Touchable,
  Text,
} from "react-native";
import { Entypo } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import styled from "styled-components/native";
import Moment from "moment";
import { IconSubtitles } from "@aws-amplify/ui-react";

const styles = StyleSheet.create({
  listcontainer: {
    borderWidth: 1,
  },
  buttonStyle: {
    //padding: 5,
    width: 10,
    height: 10,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
  },
  textStyle: {
    fontSize: 18,
    color: "black",
    fontFamily: "roboto-regular",
  },
  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: "space-around",
    alignSelf: "center",
    alignItems: "center",
    width: 300,

    //borderWidth: 1,
  },
  lc: {
    backgroundColor: "whitesmoke",
    height: 50,
    width: 350,
    marginBottom: 30,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 1,
  },
  cc: {
    flexDirection: "row",
    justifyContent: "center",
  },
  ic: {
    alignContent: "center",
    justifyContent: "center",
    height: 40,
    marginBottom: 15,
    borderRadius: 10,
    //   justify-content: center;

    //   height: 40px;
    //   margin-bottom: 15px
    //   border-radius: 10px;
  },
});

export default function LetterList({ item, deleteItem, editItem, viewItem }) {
  return (
    <View style={styles.cc}>
      <View style={styles.lc}>
        <View>
          {item.sent == 1 ? ( //SENT or Received ITEMS
            <TouchableOpacity
              style={styles.b}
              onPress={() => viewItem(item.id)}
            >
              <MaterialIcons name="mail" size={30} color="midnightblue" />

              <Text style={styles.textStyle}> Delivered on : </Text>

              <Text style={styles.textStyle}>
                {Moment(item.value).format("DD MMM YYYY")}
              </Text>
            </TouchableOpacity>
          ) : (
            //LETTER NOT SENT
            <TouchableOpacity
              style={styles.b}
              onPress={() => editItem(item.id)}
            >
              <MaterialIcons name="mail" size={30} color="midnightblue" />

              <Text style={styles.textStyle}> Deliver on : </Text>

              <Text style={styles.textStyle}>
                {Moment(item.value).format("DD MMM YYYY")}
              </Text>
            </TouchableOpacity>
          )}

          {/* <TouchableOpacity style={styles.b} onPress={() => editItem(item.id)}>
            <MaterialIcons name="mail" size={30} color="midnightblue" />
            {item.sent == 1 ? (
              <Text style={styles.textStyle}> Delivered on : </Text>
            ) : (
              <Text style={styles.textStyle}> Deliver on : </Text>
            )}
            <Text style={styles.textStyle}>
              {Moment(item.value).format("DD MMM YYYY")}
            </Text>
          </TouchableOpacity> */}
        </View>

        {/* <TextTask> {item.sent ? "Sent" : ""}</TextTask> */}
        {item.sent == 1 ? (
          <TouchableOpacity style={styles.ic}>
            <MaterialIcons name="check" size={30} color="midnightblue" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={styles.ic}
            onPress={() => deleteItem(item.id)}
          >
            <MaterialIcons name="delete" size={30} color="midnightblue" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

// const ComponentContainer = styled.View`
//   flex-direction: row;
//   justify-content: center;
//   height: auto;
//   width: auto;
// `;

// const TextItem = styled.Text`
//   align-items: center;
//   justify-content: center;
//   color: black;
//   width: 360px;
//   height: auto;
//   font-size: 20px;
//   margin-top: 10px;
//   margin-right: 20px;
//   margin-left: 20px
//   font-family: poppins-regular;

// `;

// const TextTask = styled.Text`
//   color: blue;
//   font-size: 15px;
//   margin-right: 20px;
//   font-family: poppins-regular;
//   border-radius: 10px;
//   width: 200px;
// `;

// const IconContainer = styled.TouchableOpacity`
//   align-items: center;
//   justify-content: center;

//   height: 40px;
//   margin-bottom: 15px
//   border-radius: 10px;
// `;
