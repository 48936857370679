/* eslint-disable no-console */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  View,
  StyleSheet,
  Alert,
  Text,
  SafeAreaView,
  Platform,
} from "react-native";
import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import Button from "../../components/Button";
import LongButton from "../../components/LongButton";
import Input from "../../components/Input";
import CustomTextInput from "../../components/CustomTextInput";
import Separator from "../../components/Separator";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 100,
    backgroundColor: "#FFFBE7",
  },
  inputUnderLine: {
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: "grey",
    padding: 10,
    fontSize: 20,
    //textTransform: "uppercase",
    margin: 2,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
  },
});

export default function SignIn({ navigation, signIn: signInCb }) {
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const signIn = async () => {
    //setErrorMessage("logging in..1");
    if (email.length > 4 && password.length > 2) {
      //setErrorMessage("logging in..2");
      await Auth.signIn(email, password)
        .then((user) => {
          signInCb(user);
        })
        .catch((err) => {
          if (!err.message) {
            console.log("1 Error when signing in: ", err);
            Alert.alert("Error when signing in: ", err);
          } else {
            if (err.code === "UserNotConfirmedException") {
              console.log("User not confirmed");
              navigation.navigate("Confirmation", {
                email,
              });
            }
            if (err.message) {
              setErrorMessage(err.message);
            }
          }
        });
    } else {
      setErrorMessage("Provide a valid email and password");
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <CustomTextInput
        style={styles.inputUnderLine}
        placeholder="email@example.com"
        onChangeText={(text) => onChangeEmail(text)}
        keyboardType="email-address"
        autoCompleteType="email"
        autoCapitalize="none"
        autoFocus
        label="Email"
      />

      {/* <Input
        value={email}
        placeholder="email@example.com"
        onChange={(text) => onChangeEmail(text)}
        autoCompleteType="email"
        autoCapitalize="none"
        autoFocus
        keyboardType="email-address"
      /> */}
      {/* <Input
        value={password}
        placeholder="password"
        onChange={(text) => onChangePassword(text)}
        secureTextEntry
        autoCompleteType="password"
        onSubmitEditing={() => signIn()}
      /> */}

      <CustomTextInput
        style={styles.inputUnderLine}
        placeholder="Password"
        onChangeText={(text) => onChangePassword(text)}
        // keyboardType="default"
        autoCompleteType="password"
        autoCapitalize="none"
        autoFocus
        label="Password"
        secureTextEntry={true}
        onSubmitEditing={() => signIn()}
      />
      <Text>{errorMessage}</Text>
      <View style={styles.fixToText}>
        {Platform.OS == "web" ? (
          <Button onPress={() => signIn()}>Sign In</Button>
        ) : (
          <LongButton onPress={() => signIn()}>Sign In</LongButton>
        )}
      </View>
      <Separator />
      <View style={styles.fixToText}>
        {Platform.OS == "web" ? (
          <Button onPress={() => navigation.navigate("ForgetPassword")}>
            Forget Password
          </Button>
        ) : (
          <LongButton onPress={() => navigation.navigate("ForgetPassword")}>
            Forget Password
          </LongButton>
        )}
      </View>
    </SafeAreaView>
  );
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
};
