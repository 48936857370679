import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "../screens/Home";
import AddPersonScreen from "../screens/AddPerson";
import AddLetterScreen from "../screens/AddLetter";
import EditLetterScreen from "../screens/EditLetter";
import ViewLetterScreen from "../screens/ViewLetter";
import ViewAttachmentScreen from "../screens/ViewAttachment";

const AppStack = createStackNavigator();
const AppModalStack = createStackNavigator();

/*export default function App({ signOut }) {
  return (
    <AppStack.Navigator>
      <AppStack.Screen name="Home">
        {() => <Home signOut={signOut} />}
      </AppStack.Screen>
    </AppStack.Navigator>
  );
}*/

const AppNavigator = ({ signOut }) => (
  <AppModalStack.Navigator>
    <AppModalStack.Screen
      name="Home"
      options={{
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      {({ navigation }) => (
        <HomeScreen signOut={signOut} navigation={navigation} />
      )}
    </AppModalStack.Screen>

    <AppModalStack.Screen
      name="AddLetter"
      options={{
        headerShown: true,
        gestureEnabled: false,
      }}
    >
      {({ navigation }) => (
        <AddLetterScreen signOut={signOut} navigation={navigation} />
      )}
    </AppModalStack.Screen>
    <AppModalStack.Screen name="AddPerson">
      {({ route, navigation }) => (
        <AddPersonScreen
          signOut={signOut}
          navigation={navigation}
          route={route}
        />
      )}
    </AppModalStack.Screen>
    <AppModalStack.Screen
      name="EditLetter"
      options={{
        headerShown: true,
        gestureEnabled: false,
      }}
    >
      {({ route, navigation }) => (
        <EditLetterScreen
          signOut={signOut}
          navigation={navigation}
          route={route}
        />
      )}
    </AppModalStack.Screen>
    <AppModalStack.Screen name="ViewLetter">
      {({ route, navigation }) => (
        <ViewLetterScreen
          signOut={signOut}
          navigation={navigation}
          route={route}
        />
      )}
    </AppModalStack.Screen>
    <AppModalStack.Screen name="ViewAttachment">
      {({ route, navigation }) => (
        <ViewAttachmentScreen
          signOut={signOut}
          navigation={navigation}
          route={route}
        />
      )}
    </AppModalStack.Screen>
  </AppModalStack.Navigator>
);

export default AppNavigator;
