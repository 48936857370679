import React, { useState } from "react";
import { View, StyleSheet, Text, Platform } from "react-native";
import Auth from "@aws-amplify/auth";
import Button from "../../components/Button";
import LongButton from "../../components/LongButton";
import Input from "../../components/CustomTextInput";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 100,
    backgroundColor: "#FFFBE7",
  },
  inputUnderLine: {
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: "grey",
    padding: 10,
    fontSize: 20,
    //textTransform: "uppercase",
    margin: 2,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
  },
});

function ForgetPassword({ navigation }) {
  const [email, onChangeEmail] = useState("");
  const [editableInput, setEditableInput] = useState(true);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const getConfirmationCode = async () => {
    if (email.length > 4) {
      Auth.forgotPassword(email)
        .then(() => {
          setEditableInput(true);
          setConfirmationStep(true);
          setErrorMessage("");
        })
        .catch((err) => {
          if (err.message) {
            setErrorMessage(err.message);
          }
        });
    } else {
      setErrorMessage("Provide a valid email");
    }
  };

  const postNewPassword = async () => {
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        setErrorMessage("");
        navigation.navigate("SignIn");
      })
      .catch((err) => {
        if (err.message) {
          setErrorMessage(err.message);
        }
      });
  };

  return (
    <View style={styles.container}>
      <Input
        style={styles.inputUnderLine}
        placeholder="email@example.com"
        onChangeText={(text) => onChangeEmail(text)}
        editable={editableInput}
        autoCompleteType="email"
        autoCapitalize="none"
        autoFocus
        keyboardType="email-address"
        onSubmitEditing={() => getConfirmationCode()}
        label="Email"
      />

      <View style={styles.fixToText}>
        {Platform.OS == "web" ? (
          <Button onPress={() => getConfirmationCode()}>
            Reset password via email
          </Button>
        ) : (
          <LongButton onPress={() => getConfirmationCode()}>
            Reset password via email
          </LongButton>
        )}
      </View>
      {confirmationStep && (
        <>
          <Text>Check your email for the confirmation code.</Text>
          <Input
            value={code}
            placeholder="123456"
            onChangeText={(text) => setCode(text)}
          />
          <Text>New password</Text>
          <Input
            value={newPassword}
            placeholder="password"
            onChangeText={(text) => setNewPassword(text)}
            secureTextEntry
            autoCompleteType="password"
            onSubmitEditing={() => postNewPassword()}
          />
          <View style={styles.fixToText}>
            {Platform.OS == "web" ? (
              <Button onPress={() => postNewPassword()}>
                Submit new password
              </Button>
            ) : (
              <LongButton onPress={() => postNewPassword()}>
                Submit new password
              </LongButton>
            )}
          </View>
        </>
      )}
      <Text>{errorMessage}</Text>
    </View>
  );
}

export default ForgetPassword;
