import React from "react";
import { View, Text, TextInput, StyleSheet } from "react-native";
import Button from "../components/Button";

const styles = StyleSheet.create({
  container: {
    height: 65,
    position: "relative",
    paddingTop: 5,
  },
  labelContainer: {
    position: "absolute",
    backgroundColor: "#FFFBE7",
    top: -8,
    left: 25,
    //padding: 5,
    paddingTop: 1,
    zIndex: 50,
    margin: 10,
    borderRadius: 35,
  },
  textInput: {
    //flex: 1,
    borderWidth: 1,
    //borderColor: "steel",
    //justifyContent: "flex-end",
    //height: 34,
    borderRadius: 35,
    paddingHorizontal: 5,
    backgroundColor: "#fff",
    padding: 10,
    fontSize: 20,
    //textTransform: "uppercase",
    margin: 2,
    //alignContent: "center",
    textAlign: "center",
    width: 300,
    fontFamily: "roboto-regular",
  },
});

const CustomTextInput = ({
  label,
  style,
  onChangeText,
  onTouchStart,
  value,
  placeholder,
  keyboardType,
  autoCapitalize,
  onSubmitEditing,
  onFocus,
  submitbutton,
  editable,
  secureTextEntry,
  ...props
}) => (
  <View style={styles.container}>
    <View style={styles.labelContainer}>
      <Text>{label}</Text>
    </View>
    <TextInput
      style={styles.textInput}
      onChangeText={onChangeText}
      onClick={onTouchStart}
      onTouchStart={onTouchStart}
      value={value}
      placeholder={placeholder}
      keyboardType={keyboardType}
      autoCapitalize={autoCapitalize}
      onSubmitEditing={onSubmitEditing}
      onFocus={onFocus}
      editable={editable}
      secureTextEntry={secureTextEntry}
    />
    {/* {submitbutton == "true" ? (
      <Button onPress={() => onSubmitEditing()}>Add</Button>
    ) : (
      <Text></Text>
    )} */}
  </View>
);

export default CustomTextInput;
