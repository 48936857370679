/* eslint-disable no-console */
import React, { useState } from "react";
import { View, StyleSheet, Text, Platform } from "react-native";
import Auth from "@aws-amplify/auth";
import Button from "../../components/Button";
import LongButton from "../../components/LongButton";
import Input from "../../components/CustomTextInput";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 50,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
  },
  inputUnderLine: {
    flex: 1,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: "grey",
    padding: 10,
    fontSize: 20,
    //textTransform: "uppercase",
    margin: 2,
    width: 50,
  },
});

export default function SignUp({ navigation }) {
  const [name, onChangeName] = useState("");
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [repeatPassword, onChangeRepeatPassword] = useState("");

  const [invalidMessage, setInvalidMessage] = useState(null);

  const signUp = async () => {
    const validPassword = password.length > 5 && password === repeatPassword;
    if (validPassword) {
      setInvalidMessage(null);
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          name,
        },
        validationData: [], // optional
      })
        .then((data) => {
          console.log(data);
          console.log("navigation: ", navigation);
          navigation.navigate("Confirmation", { email });
        })
        .catch((err) => {
          if (err.message) {
            setInvalidMessage(err.message);
          }
          console.log(err);
        });
    } else {
      setInvalidMessage(
        "Password must be equal and have greater lenght than 6."
      );
    }
  };

  return (
    <View style={styles.container}>
      <Input
        style={styles.inputUnderLine}
        value={name}
        placeholder="Name"
        onChangeText={(text) => onChangeName(text)}
        autoFocus
        label="Name"
      />
      <Input
        value={email}
        placeholder="email@example.com"
        onChangeText={(text) => onChangeEmail(text)}
        autoCapitalize="none"
        autoCompleteType="email"
        keyboardType="email-address"
        label="Email"
      />
      <Input
        value={password}
        placeholder="password"
        onChangeText={(text) => onChangePassword(text)}
        secureTextEntry
        autoCompleteType="password"
        label="Password"
      />
      <Input
        value={repeatPassword}
        placeholder="Repeat password"
        onChangeText={(text) => onChangeRepeatPassword(text)}
        secureTextEntry
        autoCompleteType="password"
        label="Repeat password"
        onSubmitEditing={() => signUp()}
      />
      <View style={styles.fixToText}>
        {Platform.OS == "web" ? (
          <Button onPress={() => signUp()}>Sign Up</Button>
        ) : (
          <LongButton onPress={() => signUp()}>Sign Up</LongButton>
        )}
      </View>
      <Text>{invalidMessage}</Text>
    </View>
  );
}
