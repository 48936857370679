import React, { useRef, useState, Component, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  FlatList,
} from "react-native";
import * as mime from "react-native-mime-types";
import Button from "../components/Button";
import LongButton from "../components/LongButton";
import Input from "../components/Input";
import TextBox from "../components/Text";
import DropDown from "../components/DropDown";
import { Platform } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import AttachmentList from "../components/AttachmentList";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";
//import * as Compressor from "react-native-compressor";
import Toast from "react-native-root-toast";
import DTPicker from "../components/DTPicker";

import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

import Moment from "moment";
import { Keyboard } from "react-native-web";

// const DTPicker = Platform.select({
//   ios: () => require("../components/DTPicker").default,
//   android: () => require("../components/DTPicker").default,
//   default: () => require("../components/DTPicker").default,
// })();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 2,
    margin: 10,
    zIndex: 30,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    //borderWidth: 1,
    flex: 1,
    flexDirection: "row",
    zIndex: 1,
    elevation: 1,
    position: "relative",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "center",
    justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,
    zIndex: 20,
    position: "relative",
  },
  top: {
    flex: 0.15,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  middle: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    zIndex: 10,
    position: "relative",
  },
  textbox: {
    //flex: 1,
    //backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    position: "relative",
    zIndex: 2,
    elevation: 2,
    fontFamily: "roboto-regular",
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  dropdown: {
    flex: 1,
    position: "relative",
    zIndex: 1,
    elevation: 1,
  },
  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    paddingRight: 20,
    justifyContent: "space-around",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    width: 200,
    fontFamily: "roboto-regular",
    // borderWidth: 1,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  itemStyle: {
    // fontFamily: "serif",
    alignSelf: "center",
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
  },
  bottomText: {
    // fontFamily: "serif",
    alignSelf: "center",
    fontSize: 20,
    fontFamily: "roboto-regular",
  },
  tinyLogo: {
    alignSelf: "center",
    width: 50,
    height: 45,
  },
  titleLogo: {
    alignSelf: "center",
    width: 160,
    height: 50,
  },
  logo: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "space-around",
    paddingTop: 0,
    //borderWidth: 1,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
    fontFamily: "roboto-regular",
  },
  flatliststyle: {
    // paddingTop: 30,
    //borderWidth: 1,
    //height: 10,
  },
  attachHeight: {
    height: 80,
  },
});

export default function Letter({ route, signOut, navigation }) {
  //-------------------START Of UPLOAD MEDIA FUNCTIONALITY-----------------------
  const [asset, setAsset] = useState(null);
  const [progressText, setProgressText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const selectFile = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    //console.log(result);

    if (!result.cancelled) {
      setProgressText("");
      setAsset(result);

      const atemp = {
        type: result.type,
        uri: result.uri,
        width: result.width,
        height: result.height,
        s3key: "",
      };
      //Begin ---NEW add upload functionality here
      if (isLoading) return;
      setisLoading(true);
      // console.log("URI", result.uri);
      const img = await fetchResourceFromURI(result.uri); //uncomment if not using compression
      // var img;
      // if (result.type == "video") {
      //   img = await Video.compress(
      //     result.uri,
      //     {
      //       compressionMethod: "auto",
      //     },
      //     (progress) => {
      //       if (backgroundMode) {
      //         console.log("Compression Progress: ", progress);
      //       } else {
      //         setCompressingProgress(progress);
      //       }
      //     }
      //   );
      // } else if (result.type == "image") {
      //   img = await Compressor.Image.compress(result.uri, {
      //     compressionMethod: "auto",
      //   });
      // } else {
      //   img = await fetchResourceFromURI(result.uri);
      // }

      const extension = result.uri.split(".").pop();
      //console.log("extension:", extension);
      const filename =
        Math.random().toString(36).substring(2, 30) +
        Math.random().toString(36).substring(2, 30) +
        +"." +
        extension;
      result.uri = filename;
      //Adding Compression begin

      //Adding Compression END
      const mimetype = mime.lookup(extension);

      //return Storage.put(result.uri, file5, {
      //added after compression
      return Storage.put(result.uri, img, {
        level: "public",
        contentType: mimetype,

        StorageClass: "GlacierInstantRetrieval",
        progressCallback(uploadProgress) {
          setProgressText(
            `Progress: ${Math.round(
              (uploadProgress.loaded / uploadProgress.total) * 100
            )} %`
          );
          console.log(
            `Progress: ${uploadProgress.loaded}/${uploadProgress.total}`
          );
        },
      })
        .then(async (res) => {
          setAsset(null);
          setisLoading(false);
          //setAttachment(res);
          //console.log(attachment);
          atemp.s3key = res.key;

          //console.log("atem:", atemp);
          //setAttachments((current) => [...current, atemp]);
          try {
            const newAttachmenta = await API.graphql({
              query: mutations.createAttachment,
              variables: {
                input: {
                  letterID: route.params.item,
                  type: atemp.type,
                  height: atemp.height,
                  width: atemp.width,
                  uri: atemp.uri,
                  s3key: atemp.s3key,
                },
              },
            });
          } catch (err) {
            console.log(err, "error fetching persons");
          }
          getLetter();

          Storage.get(res.key)
            .then((result) => {
              console.log("loaded file");

              //console.log(result);
            })
            .catch((err) => {
              setProgressText("Upload Error");
              console.log(err);
            });
        })
        .catch((err) => {
          setisLoading(false);
          setProgressText("Upload Error");
          console.log(err);
        });

      //End -- New add upload functionality here
    }
  };

  const fetchResourceFromURI = async (uri) => {
    const response = await fetch(uri);
    //console.log(response);
    const blob = await response.blob();
    return blob;
  };

  const deleteAttachment = async (index) => {
    //console.log(attachments[index].key);
    //console.log("index", index);
    Storage.remove(index.s3key)
      .then(async (result) => {
        try {
          const id2 = await API.graphql({
            query: mutations.deleteAttachment,
            variables: {
              input: {
                id: index.id,
                //_version: vers,
              },
            },
          });
        } catch (err) {
          console.log(err, "error fetching Letters");
        }
      })
      .then(() => {
        getLetter();
      })
      .catch((err) => {
        setProgressText("delete Error");
        console.log(err);
      });
  };
  const removeAllAttachment = async () => {
    //console.log(attachments[index].key);
    console.log("removeAllAttachments");
    attachments.forEach((item) => {
      Storage.remove(item.s3key)
        .then((result) => {
          //do nothing
          console.log("deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const viewAttachment = (key) => {
    navigation.navigate("ViewAttachment", { item: key });
  };
  //--------------------END of UPLOAD MEDIA FUNCTIONALITY------------------------
  const goback = () => {
    //route.params.setDirty(true);
    navigation.goBack();
  };

  //console.log("inside edit page:", route.params.item);

  const addPerson = () => {
    navigation.navigate("AddPerson");
  };
  const isMounted = useRef(false);

  const [sent, setSent] = useState(2);
  const [items, setItems] = useState([]);
  const [letter, onChangeLetter] = useState("");
  const [deliverydate, onChangeDeliveryDate] = useState("");
  const [openDT, setOpenDT] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const isFocused = useIsFocused();
  const [arrSelectedPersonNames, setArrSelectedPersonNames] = useState([]);
  const [arrSelectedPersons, setArrSelectedPersons] = useState([]);
  const [arrSelectedPersonLetterId, setarrSelectedPersonLetterId] = useState(
    []
  );
  const [letterid, setLetterid] = useState();

  const onChangeDate = (event, selectedDate) => {
    console.log(selectedDate);
    const currentDate = selectedDate;
    //setShow(false);
    onChangeDeliveryDate(selectedDate);
    setOpenDT(false);
  };

  const getPersons = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(queries.listPersons));

      const arr = data.listPersons.items.map((person) => ({
        value: person.id,
        label: person.fullname,
      }));
      //console.log(arr);
      setItems(arr);
    } catch (err) {
      console.log(err, "error fetching persons");
    }
  };
  const getLetter = async () => {
    //console.log("letterid", route.params.item);
    const getLetterEdit = /* GraphQL */ `
      query MyQuery($lid: ID!) {
        getLetters(id: $lid) {
          person {
            items {
              id
              letterID
              person {
                id
                fullname
              }
            }
          }
          id
          DeliveryDate
          Content
          Sent
          attachments {
            items {
              id
              letterID
              s3key
              height
              type
              updatedAt
              uri
              width
            }
          }
        }
      }
    `;

    try {
      const data2 = await API.graphql(
        graphqlOperation(getLetterEdit, { lid: route.params.item })
      );

      var PArr = data2.data.getLetters.person.items;
      //var AttachArr = data2.data.getLetters.attachments.items;
      const AttachArr = data2.data.getLetters.attachments.items.map((att) => ({
        type: att.type,
        uri: att.uri,
        width: att.width,
        height: att.height,
        s3key: att.s3key,
        id: att.id,
        letterID: att.letterID,
      }));
      setAttachments(AttachArr);
      //console.log(PArr);
      //console.log(data2.data.getLetters);
      var p;
      var arrSelectedPerson = [];
      var arrNames = [];
      var arrIds = [];

      for (p of PArr) {
        //setValue(p.person.id);
        //console.log(p.person.id);
        arrSelectedPerson.push(p.person.id);
        arrNames.push(p.person.fullname);
        arrIds.push(p.id);
      }
      //console.log(arrSelectedPersonNames);
      setarrSelectedPersonLetterId(arrIds);
      setArrSelectedPersons(arrSelectedPerson);
      setValue(arrSelectedPerson);
      setArrSelectedPersonNames(arrNames);
      console.log(data2.data.getLetters.DeliveryDate);
      const D = new Date("" + data2.data.getLetters.DeliveryDate + "T00:00:00");

      onChangeDeliveryDate(D);
      onChangeLetter(data2.data.getLetters.Content);
      setSent(data2.data.getLetters.Sent);

      //build the attachments array
    } catch (err) {
      console.log(err, "error fetching data");
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getPersons()
      .then(setLetterid(route.params.item))
      .then(getLetter())
      .then((isMounted.current = false));
  }, [isFocused]);

  /*

  useEffect(() => {
    getPersons();
    setLetterid(route.params.item);
    getLetter();
  }, [isFocused]);
  */

  const save = async (tt) => {
    console.log("Saving");
    const savetype = tt == "schedule" ? 0 : 2;
    try {
      const letterDetails = {
        Content: letter,
        DeliveryDate: Moment(deliverydate).format("YYYY-MM-DD"),
        Sent: savetype,
        id: letterid,
      };

      //console.log(value[0]); // Person ID
      //console.log(deliverydate); //Delivery Date

      const newLetter = await API.graphql({
        query: mutations.updateLetters,
        variables: {
          input: letterDetails,
        },
      })
        .then(async () => {
          // .then(async () => {
          //console.log("newLetter: ", newLetter);

          //console.log(arrSelectedPersons);
          //console.log(value);
          //CREATE NEW PERSONS

          for (const personI of value) {
            if (arrSelectedPersons.indexOf(personI) < 0) {
              //console.log("found a new person");
              //if person does not exist in the array
              const newLetterPerson = await API.graphql({
                query: mutations.createLetterToPerson,
                variables: {
                  input: {
                    letterToPersonLettersId: letterid,
                    letterToPersonPersonId: personI,
                    letterID: letterid,
                  },
                },
              });
            }
          }

          //DELETE the ones we removed
          //loop through the old array and find anyone not in the current array.
          let i = 0;
          for (const personI of arrSelectedPersons) {
            if (value.indexOf(personI) < 0) {
              console.log("delete person");

              // console.log(cLetters);
              //console.log("got all child IDs:", arrChildID);
              // define the graphql for deleting the Child table
              //deleteLetterToPerson;

              //console.log("calling delete of ", arrSelectedPersonLetterId);
              await API.graphql({
                query: mutations.deleteLetterToPerson,
                variables: {
                  input: {
                    id: arrSelectedPersonLetterId[i], //TODO
                    //_version: vers,
                  },
                },
              });
            }
            i++;
          }
        })
        .then(goback());

      //.then(goback());
      // });
      //letters = letters + 1;
      //console.log(newLetter);
    } catch (err) {
      console.log(err, "error creating letters");
    }

    //if (!isMounted.current) goback();
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setOpen(false);
        Keyboard.dismiss;
      }}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform?.OS == "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
          enabled
          keyboardVerticalOffset={100}
        >
          <View style={styles.middle}>
            <View style={styles.b}>
              <Button onPress={() => addPerson()}>Add a new Recipient </Button>

              {/* <Button onPress={() => goback()}>Back</Button> */}
            </View>

            <DropDown
              open={open}
              value={value}
              items={items}
              setOpen={setOpen}
              setValue={setValue}
              setItems={setItems}
            />

            {deliverydate != "" ? (
              Platform.OS == "web" ? (
                <DTPicker
                  onChangeDeliveryDate={onChangeDeliveryDate}
                  deliverydate={deliverydate}
                  openDT={openDT}
                  setOpenDT={setOpenDT}
                />
              ) : (
                <DTPicker
                  onChangeDeliveryDate={onChangeDate}
                  deliverydate={deliverydate}
                  openDT={openDT}
                  setOpenDT={setOpenDT}
                />
              )
            ) : (
              <View></View>
            )}

            <TextBox
              value={letter}
              //placeholder="letter"
              onChange={(letter) => onChangeLetter(letter)}
              underlineColorAndroid="transparent"
              placeholder="Type your letter here"
              placeholderTextColor="grey"
              numberOfLines={10}
              multiline={true}
            />
            {/* START of MEDIA */}
            <View style={styles.fixToText}>
              <LongButton onPress={selectFile}>
                {asset ? "Uploading" : "Add"} Attachment{" "}
                {asset ? ". Please Wait..." : ""}
              </LongButton>
            </View>
            <View style={styles.attachHeight}>
              <FlatList
                style={styles.flatliststyle}
                data={attachments}
                renderItem={({ item }) => (
                  <AttachmentList
                    item={item}
                    deleteItem={deleteAttachment}
                    viewItem={viewAttachment}
                  />
                )}
              />
            </View>
            {/* END of MEDIA */}
            <View style={styles.fixToText}>
              <LongButton onPress={() => save("schedule")}>
                Schedule Letter
              </LongButton>
              <LongButton onPress={() => save("draft")}>
                Save as Draft
              </LongButton>
            </View>

            <Text>{invalidMessage}</Text>
          </View>
        </KeyboardAvoidingView>
      </View>
    </TouchableWithoutFeedback>
  );
}
