/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      emailId
      fullname
      createdAt
      updatedAt
      owner
      letters {
        nextToken
      }
    }
  }
`;
export const listPersons = /* GraphQL */ `
  query ListPersons(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailId
        fullname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const sortByfullname = /* GraphQL */ `
  query SortByfullname(
    $fullname: String
    $sortDirection: ModelSortDirection
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SortByfullname(
      fullname: $fullname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailId
        fullname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLetters = /* GraphQL */ `
  query GetLetters($id: ID!) {
    getLetters(id: $id) {
      id
      Content
      DeliveryDate
      Sent
      readers
      createdAt
      updatedAt
      owner
      person {
        nextToken
      }
      attachments {
        nextToken
      }
    }
  }
`;
export const listLetterss = /* GraphQL */ `
  query ListLetterss(
    $filter: ModelLettersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLetterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const sortByDate = /* GraphQL */ `
  query SortByDate(
    $DeliveryDate: AWSDate
    $sortDirection: ModelSortDirection
    $filter: ModelLettersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SortByDate(
      DeliveryDate: $DeliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const sortByDelivered = /* GraphQL */ `
  query SortByDelivered(
    $Sent: Int
    $DeliveryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLettersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SortByDelivered(
      Sent: $Sent
      DeliveryDate: $DeliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLetterToPerson = /* GraphQL */ `
  query GetLetterToPerson($id: ID!) {
    getLetterToPerson(id: $id) {
      id
      letterID
      createdAt
      updatedAt
      person {
        id
        emailId
        fullname
        createdAt
        updatedAt
        owner
      }
      letters {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listLetterToPersons = /* GraphQL */ `
  query ListLetterToPersons(
    $filter: ModelLetterToPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLetterToPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        letterID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const personByLetter = /* GraphQL */ `
  query PersonByLetter(
    $letterID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLetterToPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PersonByLetter(
      letterID: $letterID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        letterID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      letterID
      type
      height
      width
      uri
      s3key
      readers
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        letterID
        type
        height
        width
        uri
        s3key
        readers
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
