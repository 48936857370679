/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      emailId
      fullname
      createdAt
      updatedAt
      owner
      letters {
        nextToken
      }
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      emailId
      fullname
      createdAt
      updatedAt
      owner
      letters {
        nextToken
      }
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      emailId
      fullname
      createdAt
      updatedAt
      owner
      letters {
        nextToken
      }
    }
  }
`;
export const createLetters = /* GraphQL */ `
  mutation CreateLetters(
    $input: CreateLettersInput!
    $condition: ModelLettersConditionInput
  ) {
    createLetters(input: $input, condition: $condition) {
      id
      Content
      DeliveryDate
      Sent
      readers
      createdAt
      updatedAt
      owner
      person {
        nextToken
      }
      attachments {
        nextToken
      }
    }
  }
`;
export const updateLetters = /* GraphQL */ `
  mutation UpdateLetters(
    $input: UpdateLettersInput!
    $condition: ModelLettersConditionInput
  ) {
    updateLetters(input: $input, condition: $condition) {
      id
      Content
      DeliveryDate
      Sent
      readers
      createdAt
      updatedAt
      owner
      person {
        nextToken
      }
      attachments {
        nextToken
      }
    }
  }
`;
export const deleteLetters = /* GraphQL */ `
  mutation DeleteLetters(
    $input: DeleteLettersInput!
    $condition: ModelLettersConditionInput
  ) {
    deleteLetters(input: $input, condition: $condition) {
      id
      Content
      DeliveryDate
      Sent
      readers
      createdAt
      updatedAt
      owner
      person {
        nextToken
      }
      attachments {
        nextToken
      }
    }
  }
`;
export const createLetterToPerson = /* GraphQL */ `
  mutation CreateLetterToPerson(
    $input: CreateLetterToPersonInput!
    $condition: ModelLetterToPersonConditionInput
  ) {
    createLetterToPerson(input: $input, condition: $condition) {
      id
      letterID
      createdAt
      updatedAt
      person {
        id
        emailId
        fullname
        createdAt
        updatedAt
        owner
      }
      letters {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateLetterToPerson = /* GraphQL */ `
  mutation UpdateLetterToPerson(
    $input: UpdateLetterToPersonInput!
    $condition: ModelLetterToPersonConditionInput
  ) {
    updateLetterToPerson(input: $input, condition: $condition) {
      id
      letterID
      createdAt
      updatedAt
      person {
        id
        emailId
        fullname
        createdAt
        updatedAt
        owner
      }
      letters {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteLetterToPerson = /* GraphQL */ `
  mutation DeleteLetterToPerson(
    $input: DeleteLetterToPersonInput!
    $condition: ModelLetterToPersonConditionInput
  ) {
    deleteLetterToPerson(input: $input, condition: $condition) {
      id
      letterID
      createdAt
      updatedAt
      person {
        id
        emailId
        fullname
        createdAt
        updatedAt
        owner
      }
      letters {
        id
        Content
        DeliveryDate
        Sent
        readers
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      letterID
      type
      height
      width
      uri
      s3key
      readers
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      letterID
      type
      height
      width
      uri
      s3key
      readers
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      letterID
      type
      height
      width
      uri
      s3key
      readers
      createdAt
      updatedAt
      owner
    }
  }
`;
