import { Text, StyleSheet, TouchableHighlight, View } from "react-native";
import React from "react";

const Separator = () => <View style={styles.separator} />;
const styles = StyleSheet.create({
  separator: {
    marginVertical: 8,

    borderBottomColor: "#737373",
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

export default Separator;
