import React, { useState } from "react";

import { View, Text, StyleSheet, Image, Platform } from "react-native";
import LongButton from "../components/LongButton";
import Button from "../components/Button";
import Input from "../components/CustomTextInput";
import TextBox from "../components/Text";
import DropDown from "../components/DropDown";

import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import Toast from "react-native-root-toast";
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 2,
    margin: 10,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    borderWidth: 1,
    flex: 1,
    flexDirection: "row",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "center",
    justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,
  },
  top: {
    flex: 0.15,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  middle: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    borderWidth: 0,
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    justifyContent: "space-around",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    width: 200,

    // borderWidth: 1,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  itemStyle: {
    // fontFamily: "serif",
    alignSelf: "center",
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
  },
  bottomText: {
    // fontFamily: "serif",
    alignSelf: "center",
    fontSize: 20,
  },
  tinyLogo: {
    alignSelf: "center",
    width: 50,
    height: 45,
  },
  titleLogo: {
    alignSelf: "center",
    width: 160,
    height: 50,
  },
  logo: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "space-around",
    paddingTop: 0,
    //borderWidth: 1,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
  },
});

export default function Person({ route, signOut, navigation }) {
  const goback = () => {
    navigation.goBack();
  };

  const [email, onChangeEmail] = useState("");
  const [name, onChangeName] = useState("");
  const [invalidMessage, setInvalidMessage] = useState(null);

  const save = async () => {
    const personDetails = {
      fullname: name,
      emailId: email,
    };

    const newPerson = await API.graphql({
      query: mutations.createPerson,
      variables: { input: personDetails },
    });
    navigation.goBack();
    route.params.getPersonFunc();

    //const validPassword = password.length > 5 && password === repeatPassword;

    // if (validPassword) {
    //   setInvalidMessage(null);
    //   Auth.signUp({
    //     username: email,
    //     password,
    //     attributes: {
    //       email, // optional
    //       name,
    //     },
    //     validationData: [], // optional
    //   })
    //     .then((data) => {
    //       console.log(data);
    //       console.log("navigation: ", navigation);
    //       navigation.navigate("Confirmation", { email });
    //     })
    //     .catch((err) => {
    //       if (err.message) {
    //         setInvalidMessage(err.message);
    //       }
    //       console.log(err);
    //     });
    // } else {
    //   setInvalidMessage(
    //     "Password must be equal and have greater lenght than 6."
    //   );
    // }
  };
  return (
    <View style={styles.container}>
      <View style={styles.middle}>
        <View>
          <View style={styles.middle}>
            <Input
              value={name}
              placeholder="Name"
              onChangeText={(text) => onChangeName(text)}
              autoCapitalize="none"
              label="Name"
            />
            <Input
              value={email}
              placeholder="email@example.com"
              onChangeText={(text) => onChangeEmail(text)}
              autoCapitalize="none"
              autoCompleteType="email"
              keyboardType="email-address"
              label="email"
            />

            <View style={styles.fixToText}>
              {Platform.OS == "web" ? (
                <Button onPress={() => save()}>Save</Button>
              ) : (
                <LongButton onPress={() => save()}>Save</LongButton>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
