import { StatusBar } from "expo-status-bar";
import { React, useState, useEffect } from "react";
import { StyleSheet, Text, View, SafeAreaView } from "react-native";
import AppNavigation from "./src/navigation";
import config from "./aws-exports";
import Amplify from "@aws-amplify/core";
import * as Font from "expo-font";
import AppLoading from "expo-app-loading";
import { Audio } from "expo-av";
import { RootSiblingParent } from "react-native-root-siblings";

Amplify.configure(config);

export default function App() {
  useEffect(() => {
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
  }, []);

  const [dataLoaded, setDataLoaded] = useState(false);
  if (!dataLoaded) {
    return (
      <AppLoading
        startAsync={fetchFonts}
        onFinish={() => setDataLoaded(true)}
        onError={() => {
          console.log("error loading app");
        }}
        style={styles.container}
      />
    );
  }
  return (
    <RootSiblingParent>
      <SafeAreaView style={styles.container}>
        <AppNavigation />
        <StatusBar style="auto" />
      </SafeAreaView>
    </RootSiblingParent>
  );
}

const fetchFonts = () => {
  return Font.loadAsync({
    "roboto-bold": require("./assets/fonts/Roboto-Bold.ttf"),
    "roboto-italic": require("./assets/fonts/Roboto-Italic.ttf"),
    "roboto-regular": require("./assets/fonts/Roboto-Regular.ttf"),
  });
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFBE7",
  },
});
