import React from "react";
import {
  Text,
  StyleSheet,
  TouchableHighlight,
  TouchableWithoutFeedback,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";

const styles = StyleSheet.create({
  buttonStyle: {
    padding: 2,
    backgroundColor: "#2a6bcc",
    borderColor: "#2a6bcc",
    borderRadius: 10,
    //borderWidth: 2,
    alignSelf: "center",
  },
  textStyle: {
    fontSize: 18,
    color: "black",
    fontFamily: "roboto-regular",
  },
});

const DropDown = ({
  onChange,
  open,
  value,
  items,
  setOpen,
  setValue,
  setItems,
  onPress,
}) => (
  <DropDownPicker
    // onPress={onPress}
    // mode="BADGE"
    // showBadgeDot={true}
    textStyle={styles.textStyle}
    placeholder="Folder"
    // containerStyle={{
    //   height: 50,
    //   //borderWidth: 5,
    //   paddingLeft: 5,
    //   borderColor: "#2a6bcc",
    // }}
    dropDownDirection="BOTTOM"
    // itemStyle={{ alignItems: "center" }}
    // labelStyle={{ fontSize: 20, color: "#fff" }}
    // activeItemStyle={{ alignItems: "center" }}
    // placeholderStyle={{ fontWeight: "bold" }}
    // activeLabelStyle={{ color: "Blue" }}
    // arrowStyle={{ marginRight: 10 }}
    // arrowIconStyle={{
    //   width: 10,
    //   height: 10,
    // }}
    // tickIconStyle={{
    //   width: 20,
    //   height: 20,
    // }}
    // closeIconStyle={{
    //   width: 20,
    //   height: 20,
    // }}
    // iconContainerStyle={{
    //   marginRight: 10,
    // }}
    // arrowIconContainerStyle={{
    //   marginRight: 10,
    // }}
    // tickIconContainerStyle={{
    //   marginRight: 100,
    // }}
    showTickIcon={false}
    // closeIconContainerStyle={{
    //   marginRight: 10,
    // }}
    // selectedItemLabelStyle={{ color: "red" }}
    // multiple={false}
    onChangeText={onChange}
    onChangeValue={onChange}
    open={open}
    value={value}
    items={items}
    setOpen={setOpen}
    setValue={setValue}
    setItems={setItems}
    closeAfterSelecting={true}
    closeOnBackPressed={true}
    close
    // itemSeparator={true}
    // listItemContainer={{
    //   height: 40,
    //   fontSize: 20,
    // }}
    // listItemLabelStyle={{
    //   fontSize: 25,
    // }}
    // dropDownContainerStyle={{
    //   backgroundColor: "#dfdfdf",
    //   padding: 5,
    // }}
    // badgeStyle={{
    //   padding: 5,
    // }}
    // badgeTextStyle={{
    //   fontStyle: "italic",
    //   fontSize: 15,
    // }}
    // badgeDotStyle={{
    //   borderRadius: 5,
    // }}
    // badgeSeparatorStyle={{
    //   width: 30,
    // }}
    // badgeColors={["yellow"]}
    // badgeDotColors={["blue"]}
    //value={value}
  />
);

export default DropDown;
