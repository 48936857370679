import React, { useRef, useState, Component, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  FlatList,
} from "react-native";
import Button from "../components/Button";
import LongButton from "../components/LongButton";
import Input from "../components/Input";
import TextBox from "../components/Text";
import DropDown from "../components/DropDown";
import Separator from "../components/Separator";
import { Platform } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import AttachmentList from "../components/AttachmentList";
import DTPicker from "../components/DTPicker";

import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

import Moment from "moment";
import { Keyboard } from "react-native-web";

// const DTPicker = Platform.select({
//   ios: () => require("../components/DTPicker").default,
//   android: () => require("../components/DTPicker").default,
//   default: () => require("../components/DTPicker").default,
// })();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 2,
    margin: 10,
    zIndex: 30,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    //borderWidth: 1,
    flex: 1,
    flexDirection: "row",
    zIndex: 1,
    elevation: 1,
    position: "relative",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "center",
    justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,
    zIndex: 20,
    position: "relative",
  },
  top: {
    flex: 0.15,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  middle: {
    flex: 1,
    backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    zIndex: 10,
    position: "relative",
  },
  textbox: {
    //flex: 1,
    //backgroundColor: "#FFFBE7",
    // borderWidth: 0,
    position: "relative",
    zIndex: 2,
    elevation: 2,
    fontFamily: "roboto-regular",
  },
  bottom: {
    flex: 0.1,
    backgroundColor: "#FFFBE7",
    borderWidth: 2,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
  },

  dropdown: {
    flex: 1,
    position: "relative",
    zIndex: 1,
    elevation: 1,
  },
  b: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "flex-end",
    paddingTop: 10,
    paddingRight: 20,
    justifyContent: "space-around",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    width: 200,
    fontFamily: "roboto-regular",
    // borderWidth: 1,
  },
  button2: {
    borderWidth: 4,
    paddingRight: 5,
    padding: 30,
  },
  itemStyle: {
    // fontFamily: "serif",
    alignSelf: "center",
    padding: 5,
    backgroundColor: "#2a6bcc",
    borderColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    alignSelf: "center",
  },
  bottomText: {
    // fontFamily: "serif",
    alignSelf: "center",
    fontSize: 20,
    fontFamily: "roboto-regular",
  },
  tinyLogo: {
    alignSelf: "center",
    width: 50,
    height: 45,
  },
  titleLogo: {
    alignSelf: "center",
    width: 160,
    height: 50,
  },
  logo: {
    //flex: 0.5,
    flexDirection: "row",
    alignContent: "space-around",
    paddingTop: 0,
    //borderWidth: 1,
  },
  fixToText: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
    fontFamily: "roboto-regular",
  },
  textstyle: {
    //flex: 0.5,
    fontSize: 20,
    fontFamily: "roboto-regular",
    // borderWidth: 1,
  },
  flatliststyle: {
    // paddingTop: 30,
    //borderWidth: 1,
    //height: 10,
  },
  attachHeight: {
    height: 150,
  },
});

export default function Letter({ route, signOut, navigation }) {
  const goback = () => {
    navigation.goBack();
  };

  //console.log("inside edit page:", route.params.item);
  const viewAttachment = (key) => {
    navigation.navigate("ViewAttachment", { item: key });
  };
  const addPerson = () => {
    navigation.navigate("AddPerson");
  };
  const isMounted = useRef(false);
  const [attachments, setAttachments] = useState([]);
  const [sent, setSent] = useState(2);
  const [items, setItems] = useState([]);
  const [letter, onChangeLetter] = useState("");
  const [deliverydate, onChangeDeliveryDate] = useState("");
  const [writtendate, onChangeWrittenDate] = useState("");
  const [openDT, setOpenDT] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const isFocused = useIsFocused();
  const [arrSelectedPersonNames, setArrSelectedPersonNames] = useState([]);
  const [arrSelectedPersons, setArrSelectedPersons] = useState([]);
  const [arrSelectedPersonLetterId, setarrSelectedPersonLetterId] = useState(
    []
  );
  const [letterid, setLetterid] = useState();

  const onChangeDate = (event, selectedDate) => {
    console.log(selectedDate);
    const currentDate = selectedDate;
    //setShow(false);
    onChangeDeliveryDate(selectedDate);
    setOpenDT(false);
  };

  const getPersons = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(queries.listPersons));

      const arr = data.listPersons.items.map((person) => ({
        value: person.id,
        label: person.fullname,
      }));
      //console.log(arr);
      setItems(arr);
    } catch (err) {
      console.log(err, "error fetching persons");
    }
  };
  const getLetter = async () => {
    const getLetterEdit = /* GraphQL */ `
      query MyQuery($lid: ID!) {
        getLetters(id: $lid) {
          person {
            items {
              id
              letterID
              person {
                id
                fullname
              }
            }
          }
          id
          DeliveryDate
          Content
          Sent
          createdAt
          attachments {
            items {
              id
              letterID
              s3key
              height
              type
              updatedAt
              uri
              width
            }
          }
        }
      }
    `;

    try {
      const data2 = await API.graphql(
        graphqlOperation(getLetterEdit, { lid: route.params.item })
      );
      var PArr = data2.data.getLetters.person.items;
      //var AttachArr = data2.data.getLetters.attachments.items;
      const AttachArr = data2.data.getLetters.attachments.items.map((att) => ({
        type: att.type,
        uri: att.uri,
        width: att.width,
        height: att.height,
        s3key: att.s3key,
        id: att.id,
        letterID: att.letterID,
      }));
      setAttachments(AttachArr);
      var PArr = data2.data.getLetters.person.items;
      //console.log(PArr);
      //console.log(PArr);
      var p;
      var arrSelectedPerson = [];
      var arrNames = [];
      var arrIds = [];

      for (p of PArr) {
        //setValue(p.person.id);
        //console.log(p.person.id);
        arrSelectedPerson.push(p.person.id);
        arrNames.push(p.person.fullname);
        arrIds.push(p.id);
      }
      //console.log(arrSelectedPersonNames);
      setarrSelectedPersonLetterId(arrIds);
      setArrSelectedPersons(arrSelectedPerson);
      setValue(arrSelectedPerson);
      setArrSelectedPersonNames(arrNames);
      const D = new Date("" + data2.data.getLetters.DeliveryDate + "T00:00:00");

      onChangeDeliveryDate(D);

      //const W = new Date("" + data2.data.getLetters.createdAt + "T00:00:00");
      onChangeWrittenDate(data2.data.getLetters.createdAt);

      onChangeLetter(data2.data.getLetters.Content);
      setSent(data2.data.getLetters.Sent);
      // console.log(sent);

      // const arr = data.listPersons.items.map((person) => ({
      //   value: person.id,
      //   label: person.fullname,
      // }));
      //console.log(arr);
      //setItems(arr);
    } catch (err) {
      console.log(err, "error fetching persons");
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getPersons()
      .then(setLetterid(route.params.item))
      .then(getLetter())
      .then((isMounted.current = false));
  }, [isFocused]);

  /*

  useEffect(() => {
    getPersons();
    setLetterid(route.params.item);
    getLetter();
  }, [isFocused]);
  */

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setOpen(false);
        Keyboard.dismiss;
      }}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform?.OS == "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
          enabled
          keyboardVerticalOffset={100}
        >
          <View style={styles.middle}>
            <View style={styles.b}></View>
            {sent == 2 ? ( //sent letter
              <Text style={styles.textstyle}>To: {arrSelectedPersonNames}</Text>
            ) : (
              //received letter
              <Text style={styles.textstyle}>
                From: {arrSelectedPersonNames}
              </Text>
            )}
            <Text style={styles.textstyle}>
              Sent On: {Moment(deliverydate).format("DD MMM YYYY")}
            </Text>
            <Text style={styles.textstyle}>
              Written On: {Moment(writtendate).format("DD MMM YYYY")}
            </Text>
            <Separator></Separator>
            <Text style={styles.textstyle}>Message: </Text>
            <Text> </Text>
            <Text style={styles.textstyle}>{letter}</Text>
            <View></View>
            <Text>{invalidMessage}</Text>
          </View>
          {attachments != undefined && attachments.length > 0 ? (
            <View style={styles.attachHeight}>
              <Text style={styles.textstyle}>Attachments</Text>
              <FlatList
                style={styles.flatliststyle}
                data={attachments}
                renderItem={({ item }) => (
                  <AttachmentList item={item} viewItem={viewAttachment} />
                )}
              />
            </View>
          ) : (
            <View></View>
          )}
        </KeyboardAvoidingView>
      </View>
    </TouchableWithoutFeedback>
  );
}
